import newRelicLog from '@/api/log/new-relic-log';
import { omit } from 'ramda';

const { NEW_RELIC_ENTITY_GUID, NEW_RELIC_ENTITY_NAME } = import.meta.env;
const common = {
  service: NEW_RELIC_ENTITY_NAME,
  'entity.guid': NEW_RELIC_ENTITY_GUID,
  'entity.name': NEW_RELIC_ENTITY_NAME,
};

function filterSensitiveData(response = {}) {
  const filtered = JSON.parse(JSON.stringify(response));
  if (filtered.headers && filtered.headers.Authorization) filtered.headers.Authorization = '***';

  return filtered;
}
const newRelicLogsAPI = ({ msgLog, level }) => {
  if (!common.service) {
    const log = JSON.parse(msgLog);
    console.group(`[NewRelic] logger info: ${log.message}`);
    console.log({
      level, // Sentry level: Info
      log, // extra data
    });
    console.groupEnd();
    return;
  }
  const logs = {
    timestamp: Date.now(),
    message: msgLog,
    level,
    ...common,
  };

  newRelicLog(logs)
    .then((response) => {
      console.log('Log sent to New Relic:', response.data);
    })
    .catch((error) => {
      console.error('Failed to send log to New Relic:', error);
    });
};

export const newRelicLogInfo = (message, extra) => {
  const msgLog = JSON.stringify({
    message,
    info: extra,
  });
  newRelicLogsAPI({ msgLog, level: 'INFO' });
};

export const newRelicLoggerAPIStatus500 = (message, error = {}) => {
  const msgLog = JSON.stringify({
    message,
    extra: {
      headers: `${JSON.stringify(error.response?.headers)}`,
      data: `${JSON.stringify(error.response?.data)}`,
      method: `${error.config?.method}`,
      url: `${error.config?.url}`,
      response: `${JSON.stringify(error?.response)}`,
    },
  });
  newRelicLogsAPI({ msgLog, level: 'WARNING' });
};

export const newRelicLoggerLayout500 = ({ message, error = {} }) => {
  if (error?.config) error.config = filterSensitiveData(error.config);
  const errorInfo = error.stack ? JSON.stringify(omit(['stack'], error)) : JSON.stringify(error);

  const msgLog = JSON.stringify({
    message,
    errorInfo,
  });
  newRelicLogsAPI({ msgLog, level: 'WARNING' });
};

export const sendAPIErrorToNewRelicLog = (response = {}, uid = '', title = `[api error] API異常`) => {
  const filteredResponse = filterSensitiveData(response);

  const msgLog = JSON.stringify({
    message: title,
    errorInfo: {
      uid,
      response: filteredResponse,
    },
  });
  newRelicLogsAPI({ msgLog, level: 'ERROR' });
};

export const sendAnomaliesAPIToLog = (message, response) => {
  const msgLog = JSON.stringify({
    message,
    resData: response,
  });
  newRelicLogsAPI({ msgLog, level: 'INFO' });
};
