/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/vue';
import { omit } from 'ramda';
import Severity from '@/helper/sentry-severity-level.js';
import { isVitest } from '@/helper/test/test-env';

/**
 * 過濾敏感資料
 * @param {Object} response response
 * @return {Object} 過濾後的 response
 */
function filterSensitiveData(response = {}) {
  const filtered = JSON.parse(JSON.stringify(response));
  if (filtered.headers && filtered.headers.Authorization) filtered.headers.Authorization = '***';

  return filtered;
}

/**
 * Sentry logger info Helper
 * @param {string} message info message
 * @param {object} extra extra JSON object data
 */
export const sentryLoggerInfo = (message, extra = {}) => {
  if (import.meta.env.VITE_TYPE !== 'prd' && !isVitest) {
    console.group(`[Sentry] logger info: ${message}`);
    console.log({
      level: Severity.Info, // Sentry level: Info
      extra, // extra data
    });
    console.groupEnd();
  }

  Sentry.captureMessage(message, {
    level: Severity.Info, // Sentry level: Info
    extra, // extra data
  });
};

/**
 * Sentry logger status Helper
 * @param {string} message info message
 * @param {object} extra extra JSON object data
 */
export const sentryLoggerStatus500 = (message, error = {}) => {
  Sentry.captureMessage(`${message}`, {
    level: Severity.Warning,
    extra: {
      headers: `${JSON.stringify(error.response?.headers)}`,
      data: `${JSON.stringify(error.response?.data)}`,
      method: `${error.config?.method}`,
      url: `${error.config?.url}`,
      response: `${JSON.stringify(error.response)}`,
    },
  });
};

export const sentryLoggerLayout500 = (message, error = {}) => {
  if (error?.config) error.config = filterSensitiveData(error.config);
  const errorInfo = error.stack ? JSON.stringify(omit(['stack'], error)) : JSON.stringify(error);
  sentryLoggerInfo(message, {
    error_info: errorInfo,
  });
};

/**
 * api error response send to Sentry
 * @param {Object} response api response
 * @param {String} uid lms member number
 * @param {string} title title (default: [api error] 異常等待)
 */
export const sendAPIResponseTOSentryLog = (response = {}, uid = '', title = `[api error] API異常`) => {
  // 過濾敏感資料
  const filteredResponse = filterSensitiveData(response);

  console.group(`sendAPIResponseTOSentryLog`);
  console.log({
    level: Severity.Warning,
    message: title,
    extra: { uid, response: filteredResponse },
  });
  console.groupEnd();

  Sentry.captureEvent({
    level: Severity.Warning,
    message: title,
    extra: { uid, response: filteredResponse },
  });
};
