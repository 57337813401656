import { includes } from 'ramda';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import needLoginPath, { cartPath } from '@/constant/login-path';
import AuthStorageService from '@/services/AuthStorageService';
import XorCipher from '@/services/XorCipher.js';
import VerifyTokenService from '@/services/VerifyTokenService';
import { getURL, getQueryParams } from '@/helper/path';
import { isEmptyValue } from '@/helper/data-process';
import { random } from '@/helper/random';
import { sentryLoggerInfo, sentryLoggerStatus500, sendAPIResponseTOSentryLog } from '@/helper/sentry-log';
import { useLoadingStore } from '@/stores/loading';
import { useLoginStore } from '@/stores/login';
import { useLogoutStore } from '@/stores/logout';
import { newRelicLoggerAPIStatus500, newRelicLogInfo, sendAPIErrorToNewRelicLog } from '@/helper/new-relic-log';

const isDev = ['development', 'lab', 'stg'].includes(import.meta.env.VITE_TYPE);
const cipher = new XorCipher(import.meta.env.VITE_XOR_ENCRYPT_KEY);
const errorCodes500 = [500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511];
let loadingTimer = null;

/** @function checkWait 檢驗 loading 等待項目 */
function checkWait() {
  const loadingStore = useLoadingStore();
  const { getWaitPaths } = storeToRefs(loadingStore);
  const hasWait = getWaitPaths.value?.filter((item) => /-wait$/.test(item)).length > 0 || false;
  // 只剩最後一筆且該筆為 wait 時強制清除
  if (getWaitPaths.value.length === 1 && hasWait === true) {
    // console.log(`clearPaths`);
    loadingStore.clearPaths();
  }
}

/** @function checkStep 判斷網址是在 step 1,2,3 或其他 */
function checkStep(url) {
  // 正則表達式匹配 'step1', 'step2', 或 'step3'
  const stepRegex = /\/cart\/(step[123])\b/;
  const match = url.match(stepRegex);

  // 檢查匹配結果並返回相應的步驟或'其他'
  if (match) {
    // return parseInt(match[1].replace('step', ''), 10); // 'step1', 'step2', 或 'step3' 的數字部分
    return match[1];
  }
  return false;
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/** @const {string[]} cipherPaths 需做解密的 api 路徑 */
const cipherPaths = [import.meta.env.VITE_API_MEMBER_ADDRESS_BOOK, import.meta.env.VITE_API_MEMBER, import.meta.env.VITE_API_CHECKOUT_RESULT, import.meta.env.VITE_API_ORDER_DETAIL];

/** @const {string[]} verifyTokenPaths 需要驗證 header verify_token 的 api 路徑 */
const verifyTokenPaths = [import.meta.env.VITE_API_LOGIN, import.meta.env.VITE_API_MEMBER, import.meta.env.VITE_API_MEMBER_V2];

/** @const {string[]} retryPaths 非購物車中需要 retry api 路徑 (axios retry 參數) */
const retryPaths = [import.meta.env.VITE_API_CATEGORIES];

/**
 * checkPath 判斷是否為需要解密的路徑
 * @param {string} path 要檢驗的路徑
 * @param {string[]} checkPaths 符合條件的路徑 [`/api/aaa`, `/api/bbb`]
 * @returns {boolean} 是否為名單中路徑
 */
const checkPath = (path = '', checkPaths = []) => {
  const apiURL = getURL(path);
  const { pathname } = apiURL;

  return checkPaths.some((elem) => pathname.startsWith(elem));
};

/**
 * 判斷錯誤類型是否為 ECONNABORTED / Network Error / timeout
 * @param {object} error error object
 * @returns {boolean} is ECONNABORTED / Network Error / timeout
 */
const isErrorTimeout = (error) => error?.code === 'ECONNABORTED' || error?.message === 'Network Error' || error?.message.toLowerCase()?.includes('timeout') === true;

const retryRequest = async (originalConfig, retryCount) => {
  /** @const {number} timer 隨機重發時間 20-30秒 (dev 縮短時間) */
  const timer = isDev ? random(5000, 3000) : random(30000, 20000);
  const u = getURL(originalConfig.url);
  u.searchParams.set('retry', retryCount);
  originalConfig.url = u.href;
  return delay(timer).then(() => axios(originalConfig));
};

/**
 * waitAndRetryAPI
 * @param {object} error error object
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
function waitAndRetryAPI(error) {
  /**
   * @const {store} loadingStore
   * @type {Store<"loading", {loadingStatus: boolean, waitPaths: []}, {getLoadingStatus(*): *, getWaitPaths(*): Array, getWaitStatus(*): boolean}, {addWaitPath(*): void, subWaitPath(*): void, turnOnLoading(*): void}>}
   */
  const loadingStore = useLoadingStore();

  const { config: errorConfig } = error;

  // send sentry
  const loginStore = useLoginStore();
  const { user } = storeToRefs(loginStore);
  sendAPIResponseTOSentryLog(error, user.value?.lms_member_id || null, `[api error] 購物車 API 異常`);
  sendAPIErrorToNewRelicLog(error, user.value?.lms_member_id || null, `[api error] 購物車 API 異常`);
  /** @const {number} timer 隨機重發時間 40-60秒 (dev 縮短時間) */
  const timer = isDev ? random(5000, 3000) : random(60000, 40000);

  const step = checkStep(window.location.pathname);
  if (step !== false) loadingStore.addWaitPath(`${step}-wait`);

  /** @const {number} wait 等待參數標記 */
  const wait = errorConfig?.params?.wait || 0;

  const url = errorConfig?.url || '';

  // show wait component
  if (url !== '') loadingStore.addWaitPath(url);

  return delay(timer).then(() => {
    return axios
      .request({
        ...errorConfig,
        params: { ...errorConfig?.params, wait: wait + 1 },
      })
      .then(async (res) => {
        if (res?.status !== 500) await loadingStore.subWaitPath(url);
        if (res === false || res?.status !== 200) {
          res = null;
          return res;
        }
        return res;
      });
  });
}

/**
 *  auth token 過期處理
 *    > 前端 flag 已登入，後端驗證失敗 (401)
 *    > 執行登出，清除前端 flag
 *  @scope `必須`登入的頁面 /member, /cart
 * @param {object} param0
 */
const handleApiAuthError = ({ error }) => {
  const statusCode = error.response?.status || 0;

  // 只處理 401, 429, 及5xx
  if ([401, 429, ...errorCodes500].includes(statusCode) === false) return Promise.reject(error);

  const loginStore = useLoginStore();
  const { user, loggedIn } = storeToRefs(loginStore);
  const logoutStore = useLogoutStore();

  // flag 未登入不處理
  if (!loggedIn.value && !includes(window.location.pathname, cartPath)) return Promise.reject(error);

  // 不需要登入的頁面不處理。
  if (!includes(window.location.pathname, [...needLoginPath, ...cartPath])) return Promise.reject(error);

  const AuthStorage = new AuthStorageService();

  // 5xx wait (現階段先只在購物車)
  if (includes(window.location.pathname, cartPath) && (errorCodes500.includes(error.response?.status || 0) || isErrorTimeout(error)) && !checkPath(error.config.url, retryPaths)) {
    return waitAndRetryAPI(error || {});
  }

  // 401, 503 retry
  return new Promise((resolve) => {
    // retry
    // resolve(axios.request(error.config));
    // 確認 jwt
    const token = AuthStorage.getToken();

    const MEMBER_API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_MEMBER_V2}?t=${Date.now()}`;
    return axios
      .request({
        method: 'get',
        headers: { Authorization: token, 'Cache-Control': 'no-store' },
        url: MEMBER_API_PATH,
        params: { retry: 1 },
      })
      .then((res) => {
        if (res === false || res?.status !== 200) {
          // sentry log
          const sentryData = { uid: user.value?.lms_member_id || null, token };
          sentryLoggerInfo(`[logout] axios-retry: member-api status is not 200`, sentryData);
          newRelicLogInfo(`[logout] axios-retry: member-api status is not 200`, sentryData);
          logoutStore.logout();
          res = null;
          return resolve(res);
        }

        // 若 member 呼叫正常時重打
        return axios.request({ ...error.config, params: { ...error?.config?.params, retry: 1 } }).then((res) => {
          if (res === false || res?.status !== 200) {
            // sentry log
            const sentryData = { uid: user.value?.lms_member_id || null, token };
            sentryLoggerInfo(`[logout] axios-retry: api call failed, status not 200`, sentryData);
            newRelicLogInfo(`[logout] axios-retry: api call failed, status not 200`, sentryData);
            logoutStore.logout();
            res = null;
            return resolve(res);
          }
          return resolve(res);
        });
      });
  });
};

const globalAxios = () => {
  axios.interceptors.request.use((request) => {
    if (request === undefined) return request;
    if (request.url?.includes('/cart/')) request.timeout = 60 * 1000;

    // member_detail, member, sign_in 加上 verify_token header
    if (checkPath(request.url, verifyTokenPaths)) {
      const verifyTokenService = new VerifyTokenService();
      verifyTokenService.set();
      console.log(request.url);
      request.headers.verify_token = verifyTokenService.get();
    }
    return request;
  });
  axios.interceptors.response.use(
    async (response) => {
      // [cartPath] 設定檢查點於 api 最大期限 60 秒後 2 秒檢驗 loading 等待項目
      if (includes(window.location.pathname, cartPath)) {
        clearTimeout(loadingTimer);
        loadingTimer = setTimeout(checkWait, 62000); // 62秒檢查
      }

      // 需要驗證 header verify_token 的 api 路徑 (member_detail, member, sign_in)
      if (checkPath(response.config.url, verifyTokenPaths)) {
        const verifyTokenService = new VerifyTokenService();
        verifyTokenService.set();
        const requestVerifyToken = response.config.headers.verify_token;
        const responseVerifyToken = response.headers.verify_token;

        if (!isEmptyValue(responseVerifyToken)) {
          console.log({ requestVerifyToken, responseVerifyToken });
          const check = requestVerifyToken === responseVerifyToken;
          const queryParams = getQueryParams(response.config.url);
          const retryCount = parseInt(queryParams.retry || 0, 10);

          // 設置最大重試次數 10
          if (!check && retryCount <= 10) {
            console.log(`重試次數: ${retryCount + 1}`);
            return retryRequest(response.config, retryCount + 1);
          }
        }
      }

      // 不在名單: 內直接跳出
      if (checkPath(response.config.url, cipherPaths) === false) return response;

      // 加密內容解密後覆蓋
      if (response?.data?.message !== undefined) {
        const decryptData = await cipher.decrypt(response.data.message);

        // 成功解密時寫回 response.data
        if (decryptData !== false) response.data = decryptData;
      }

      return response;
    },
    (error) => {
      // console.group(`[globalAxios] response error`); console.log({ ...error }); console.groupEnd();

      // 購物車內的 api  5xx / ECONNABORTED / Network Error / timeout 時，並且排除 retryPaths 內容
      if (includes(window.location.pathname, cartPath) && (errorCodes500.includes(error.response?.status || 0) || isErrorTimeout(error)) && !checkPath(error.config.url, retryPaths)) {
        return waitAndRetryAPI(error || {});
      }

      const { config } = isEmptyValue(error?.config?.config?.url) ? error : error.config;

      // 檢查錯誤是否來自特定路徑 (排除購物車)
      if (checkPath(config.url, retryPaths) && !includes(window.location.pathname, cartPath)) {
        // 判斷是否設置了重試次數，並確認重試次數小於最大重試次數
        config.retryCount = config.retryCount || 0;
        if (config.retryCount < config.retry) {
          config.retryCount += 1; // 增加重試計數
          config.headers['X-Retry-Count'] = config.retryCount;
          // 進行重試
          return axios(config);
        }
      }

      // status:5xx (原429) 非購物車內的
      if ([429, ...errorCodes500].includes(error.response?.status) && !includes(window.location.pathname, cartPath)) {
        sentryLoggerStatus500(`[Fetch/XHR] axios-status: ${error.response?.status}`, error);
        newRelicLoggerAPIStatus500(`[Fetch/XHR] axios-status: ${error.response?.status}`, error);
      }
      // retry end
      if (error.config?.params?.retry !== undefined) return false;

      // console.group(`handleApiAuthError`); console.log({ error, store }); console.groupEnd();
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      return handleApiAuthError({ error });
    },
  );
};

export default globalAxios;
