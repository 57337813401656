import axios from "axios";

const API_KEY = import.meta.env.NEW_RELIC_LICENSE_KEY;
export default (logs) =>
  axios({
    method: 'post',
    headers: {
      'Api-Key': API_KEY,
      'Content-Type': 'application/json'
    },
    url: 'https://log-api.newrelic.com/log/v1',
    data: logs,
  });
